import { type FC, useEffect, useRef, useState } from "react";
// eslint-disable-next-line no-restricted-imports
import { Image as ChakraImage, type ImageProps as ChakraImageProps } from "@chakra-ui/react";

export type LazyImageProps = ChakraImageProps & {
	setClassName?: boolean;
};

export const LazyImage: FC<LazyImageProps> = (props) => {
	const imageRef = useRef<HTMLImageElement>(null);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!loaded && imageRef.current?.complete) {
			setLoaded(true);
			imageRef.current.classList.add("image-loaded");
		}
	}, []);

	return (
		<ChakraImage ref={imageRef} {...props} loading="lazy" onLoad={(e) => {
			(e.target as HTMLImageElement).classList.add("image-loaded");
			setLoaded(true);
		}}/>
	)
}